.bg-sector-text,
.submit,
.submit:hover,
.submit:focus {
    background-color: #99cbd2;
}

.submit {
    border-color: #99cbd2;
}

a,
a:hover,
a:focus {
    color: #99cbd2;
}

.link-wunderline {
    text-decoration: none;
}

.link-wunderline:hover {
    text-decoration: underline;
    text-decoration-color: #fff;
}

.lwptoc-light .lwptoc_i A {
    font-size: 18px;
    font-weight: 700;
    color: #99cbd2 !important;
}

.post-article p,
.comment-content p {
    margin-bottom: 1rem;
}

p {
    margin-bottom: 0;
}

.bg-sector {
    background-color: #89c3cb;
}

.page-item.active .page-link {
    background-color: #89c3cb;
    border-color: #fff;
}

.page-link,
.page-link:hover {
    color: #89c3cb;
}

.bg-sector-shadow-left {
    box-shadow: 7px 10px 2px #89c3cb;
}

.bg-homepage-sector-shadow-left {
    box-shadow: 13px 10px 2px #89c3cb;
}

.bg-sector-biophoto {
    box-shadow: 5px 5px 2px #89c3cb;
}

.bg-sector-shadow-right {
    box-shadow: -6px 6px 2px #89c3cb;
}

.text-shadow-softblack {
    text-shadow: 4px 3px 4px #3A4348;
}

.text-softblack {
    color: #3A4348;
}

.padding-center {
    padding-top: 10rem;
    padding-bottom: 10rem;
}

.img-flag {
    background-image: url("/wp-md-content/themes/pdf2022-child/img/flag.webp");
    background-repeat: no-repeat;
    height: 0.5rem;
    width: 4rem;
}

.logo {
    width: 14rem;
}

.height-22rem {
    height: 22rem;
}

.fs-25rem {
    font-size: 2.5rem !important;
}

.fs-28rem {
    font-size: 2.8rem !important;
}

.fs-13rem {
    font-size: 1.3rem !important;
}

.fs-footer {
    font-size: .6rem !important;
}


.fs-08rem {
    font-size: 0.8rem !important;
}

.fs-1ren {
    font-size: 1em !important;
}

.fs-18 {
    font-size: 18px !important;
}

.margin-topminus1rem {
    margin-top: -1rem !important;
}

.mt-05rem {
    margin-top: .5rem !important;
}

.ml-10 {
    margin-left: 10%;
}

.ml-13 {
    margin-left: 13%;
}

#jumbotron {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 6rem;
    padding-bottom: 6rem;
}

#home-jumbotron {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 7rem;
    padding-bottom: 7rem;
}

#newsletter-box {
    min-height: 20rem;
}

.div-wbgimg {
    height: 45vh !important;
    width: 100%;
}

body {
    padding: 0;
    margin-left: 3px;
    color: #3A4348;
    font-size: 18px;
}

.post-mincontent {
    min-height: 7rem;
}

hr {
    height: 0.18rem !important;
}

.nav-link {
    text-transform: uppercase;
}

.dropdown-item {
    color: #0000008c;
    font-weight: 700 !important;
}

.nav-link-homepage {
    border-bottom-color: #3A4348;
    border-bottom-width: 2px;
    border-bottom-style: solid;
}

.comment-reply-link,
.comment-reply-link a:hover {
    color: #fff;
    text-decoration: none;
}

#bio-title {
    text-align: center;
    margin-top: 10px;
}

.img-center-bio {
    float: none;
    margin-left: auto !important;
    margin-right: auto !important;
}

.has-text-align-center {
    padding-bottom: 1rem;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(0, 0, 0, 0.55)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

@media (min-width: 992px) {
    #photobio {
        float: right;
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .bg-sector-shadow-right {
        box-shadow: -12px 10px 2px #89c3cb;
    }

    .fs-footer {
        font-size: 1rem !important;
    }

    #bio-title {
        text-align: start;
        margin-top: 0;
    }

    .lwptoc-light .lwptoc_i A,
    body {
        font-size: 20px;
    }
    body {
        margin-left: 0;
    }
}

@media (min-width: 2001px) {
    .large-content {
        margin-left: 25% !important;
        flex: 0 0 auto;
        width: 50% !important;
    }
}
