/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// scss-docs-start import-stack
// Configuration
@import "functions";
@import "variables";
@import "mixins";
@import "utilities";

// Required
@import "root";
@import "reboot";
@import "type";
@import "images";


// Layout & components
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
@import "dropdown";
@import "pagination";
@import "close";


// Helpers
@import "helpers";

// Utilities
@import "utilities/api";
// scss-docs-end import-stack


.ml-2 {
    margin-left: ($spacer * .5) !important;
}

.text-white {
    color: #fff!important;
}