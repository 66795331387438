.wp-block-image.aligncenter,
.wp-block-image .aligncenter,
.wp-block-image.alignleft,
.wp-block-image .alignleft,
.wp-block-image.alignright,
.wp-block-image .alignright {
    display: table;
}

.wp-block-image .aligncenter {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.wp-block-image {
    margin: 0 0 1em;
}

.has-text-align-center {
    padding-top: 1rem;
    padding-bottom: 1rem;
}